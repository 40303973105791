@use "card.module"
@use "./form.module"
    
.circleSlice
    top: 0
    left: 0
    position: absolute
    pointer-events: none

    svg
        pointer-events: none

    circle
        pointer-events: stroke

.trackingData
    margin: auto
    position: relative
    width: 120px
    height: 120px
    background-color: #262626
    border-radius: 100%
    border: 8px solid transparent

.actTimeline
    width: 800px
    height: 60px
    position: relative
    background-color: #353535
    border-radius: 10px
    overflow: hidden

    .blockObject
        top: 25px
        height: 25px
        position: absolute
        border-radius: 5px

    .hourStick
        width: 3px
        border-radius: 3px
        position: absolute
        top: 5px
        height: 50px
        background-color: #474747

        span
            color: #939393
            font-size: 0.7em
            margin-top: -.25em
            display: block
            margin-left: .35em
            filter: none
            position: static

.activities
    @extend .card
    flex-direction: column

    .nav 
        display: flex
        justify-content: space-between
        width: 100%

.current
    @extend .formsubstyling
    input
        text-align: center

    span 
        color: #a3a3a3
        font-size: .65em

    div
        display: table-cell
        vertical-align: middle

    display: table
    text-align: center
    border: 25px solid #ffffff62
    border-radius: 2000px
    width: 200px
    height: 200px
    font-size: 1.5em
    vertical-align: middle
    margin-bottom: 0.5em

.colorIndicator
    z-index: 1
    position: absolute
    right: 0
    width: 1.5em
    height: 1.5em
    border-radius: 3em
    top: 0