.flyingEye
    position: absolute
    z-index: 100
    transition: 1000ms cubic-bezier(0.68, -0.6, 0.32, 1.6)
    transition-property: height, width, top, left

    & *
        transition: 1000ms
        transition-property: stroke, stop-color

.flyingEyeNoAnim
    position: absolute
    z-index: 100

.eyeWrapper 
    display: block
    position: relative
    

.eye
    position: absolute
    transition: transform 20s ease-in
    transform: scale(1)
    z-index: 2

    svg
        transition: filter 20s ease-in
        filter: contrast(1)

.eye:hover
    transform: scale(100)

    svg
        animation: triggered .2s steps(2) infinite
        filter: contrast(100)

.triggered
    animation: triggered .2s steps(2) infinite

@keyframes triggered     
    0% 
        transform: translate(2px,1px) rotate(0deg)
    
    10% 
        transform: translate(-1px,-2px) rotate(-2deg)
    
    20% 
        transform: translate(-3px,0) rotate(3deg)
    
    30% 
        transform: translate(0,2px) rotate(0deg)
    
    40% 
        transform: translate(1px,-1px) rotate(1deg)
    
    50% 
        transform: translate(-1px,2px) rotate(-1deg)
    
    60% 
        transform: translate(-3px,1px) rotate(0deg)
    
    70% 
        transform: translate(2px,1px) rotate(-2deg)

    80% 
        transform: translate(-1px,-1px) rotate(4deg)
    
    90% 
        transform: translate(2px,2px) rotate(0deg)
    
    100% 
        transform: translate(1px,-2px) rotate(-1deg)