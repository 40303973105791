@use "./card.module"
    
.menuMover
    position: absolute
    display: none
    z-index: 8
    pointer-events: none

    .contextMenu
        top: 0
        left: 0
        width: 200px
        pointer-events: auto

        .item
            margin: 0

            .itemText
                padding: .5em

.contextMenu
    @extend .card
    box-shadow: 0px 0px 2em 0px rgba(0, 0, 0, 0.95)