@use "./card.module"

.TimeProgressCards
    @extend .card
    table
        width: 500px
        max-width: 100%
    td:first-child
        width: 2em
    td:last-child
        width: 2em
    
.container
    height: 2em
    width: 100%
    background-color: #ffffff21
    border-radius: 1em
    overflow: hidden

.bar
    background-image: linear-gradient(90deg, #c50000, #f4ac00)
    height: 100%
    transition: width 1000ms linear
