.header
    position: fixed
    top: 0
    left: 0
    z-index: 3
    width: calc(100% - 2rem)
    height: 50px

    background-color: var(--header-solid, #262626) //var(--header-solid, #535353)
    color: #ffffff
    
    padding: 0 1rem

    display: flex
    flex-direction: row
    align-items: center

.headerTray
    //width: 100%
    display: flex
    flex-direction: row
    align-items: center
    flex-grow: 2
    justify-content: flex-end
    padding-left: 0

.headerStart
    justify-self: flex-start
    display: flex

.title
    position: relative
    bottom: 2px
    margin-left: .5em
    font-size: 1.75em
    font-weight: bolder
    align-self: center
    line-height: 1.3em
    background-image: linear-gradient(135deg,#ff00fe, #00f3ff)
    background-clip: text
    -webkit-text-fill-color: transparent
    color: #00000000
    user-select: none

    &::selection 
        background-color: rgba(0, 255, 255, 0)

    @media screen and (max-width: 600px) 
        font-size: 1.25em

.headerItem
    position: relative
    display: flex
    align-items: center
    justify-content: center

.headerButton
    border-radius: 50%
    background-color: #535353
    width: 40px
    height: 40px

    display: flex
    align-items: center
    justify-content: center
    margin: 0 5px
    transition: all 400ms ease-in-out

    &[data-drawer="true"]
        background-color: red
        svg
            transform: rotate(135deg)
            width: 50%

    svg
        transition: all 400ms cubic-bezier(0.68, -0.6, 0.32, 1.6)
        width: 40%
        height: auto
        color: white