@use "./style/form.module"
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap')

// @font-face 
//     font-family: "PixelFont" 
//     src: url("/FFFFORWA.TTF") format("truetype")
//     font-style: normal
//     font-weight: 400

// @font-face 
//     font-family: "PixelFont" 
//     src: url("../public/Gamepixies.ttf") format("truetype")
//     font-style: normal
//     font-weight: 400

    
:root
    font-family: 'Noto Sans TC', sans-serif
    scrollbar-color: #535353 #181a1b
    scrollbar-width: auto

body 
    font-family: 'Noto Sans TC', sans-serif
    margin: 0px
    overflow-x: hidden
    color: white
    background-color: #181a1b
    min-height: 100vh
    
input, button, submit 
    border: none
    font: inherit
    padding: 0
    box-sizing: content-box

a, a:visited, a:hover 
    color: inherit

.sketch-picker 
    background: #262626 !important

    input
        border-color: transparent !important
        background-color: #353535 !important

    *
        color: white !important