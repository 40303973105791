@use "./card.module"
@use "./form.module"

.diaryGrid
    @extend .card
    flex-direction: column

    h1 > span
        font-weight: normal
        color: #a3a3a3

    th, td
        width: 1.5em
        height: 1.5em

    .diaryControls
        display: flex
        flex-direction: row
        justify-content: space-between
        color: #a3a3a3
        width: 100%
        margin-bottom: .5em

        & span[data-bingus=true]
            color: white
            fontWeight: bold

.diaryForm
    @extend .card
    flex-direction: column

    form 
        @extend .form
        width: 320px

        .submitButton
            align-self: flex-end
            width: max-content
    
    @media screen and (min-width: 1745px)
        margin-right: 375px
        

.modal
    min-width: 300px

    .modalHeader
        width: 100%
        display: flex
        justify-content: space-between

    span
        filter: brightness(50%)
        font-size: 2em
        position: relative
        top: -0.5em
        	
        &:last-child
            //padding-right:  1.4em

    p 
        white-space: pre-wrap
    h1 
        margin-top: -10px
        padding: .5em
        //box-shadow: 0px 0px 24px 4px #353535
        background-color: #353535
        border-radius: 1em

.modalNav
    width: 100%
    display: flex
    justify-content: space-between

    span
        margin-bottom: -1.5em

    b
        margin-bottom: -1.5em
        color: #808080
        
        //border: 3px solid white
        //width: 2em
        //height: 2em
        //border-radius: .5em

.modalBody
    max-height: 65vh
    overflow-y: auto
    overflow-x: hidden

    & > div
        overflow: hidden


.title
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100px
    z-index: -1

.pixelrow
    display: flex
    flex-direction: row

.pixel 
    width: 3em
    height: 3em
    border-radius: 1.5em
    background-color: #ffffff69
    text-align: center
    display: table
    margin: .5em
    
    &[data-active="false"]


    span
        margin: auto 0
        display: table-cell
        vertical-align: middle
        font-size: 20px
        font-weight: bold
        width: 3em
        height: 100%
        filter: brightness(50%)