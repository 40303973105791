@use "./card.module"
    
.clockCard
    @extend .card

.digital
    color: #a3a3a3
    .time
        font-size: 40px
        font-weight: bolder
        color: white

        span
            color: #a3a3a3
    
.clockBody
    width: 200px
    height: 200px
    border-radius:100px
    //background: radial-gradient(circle, #e8e8e8 0%, #00f3ff 45%,#ff00fe 60%, #262626 70%)
    background-color: #c8c8c81e
    position: relative

.sek
    width: 100%
    height: 100%
    transform: rotate(45deg)
    position: absolute
    top: 0px
    left: 0px
    //transition: transform 50ms 

    div
        top: calc(50% - 1px)
        left:50%
        width: 50%
        height: 2px
        position: absolute
        background-color: red
        border-radius: 1px


.min
    @extend .sek
    div
        top: calc(50% - 2px)
        background-color:  #8d8d8d
        height: 4px
        width: 45%
        border-radius: 2px

.hr 
    @extend .sek
    div
        top: calc(50% - 3px)
        background-color: #8d8d8d
        height: 6px
        width: 35%
        border-radius: 3px

