.drawer 
    width: 100%
    

.content
    position: relative
    &:hover
        background-color: #c8c8c81e

    & > img 
        position: absolute
        left: 0
        top: calc( 50% - .25em )
        max-height: 1em
        max-width: 1em
        margin: 0 auto
        transition: 500ms transform

    & > div
        left: 0
        width: 1.5em
        height: 100%
        z-index: 1
        position: absolute

    & > span 
        margin-left: 1.5em

    //border: 1px solid #00000080



.inside
    margin-left: .5em
