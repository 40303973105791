.modalContainer
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    z-index: 7

.modalPlane
    @extend .modalContainer
    background-color: #000000b6
    z-index: 5



.modal
    max-width: calc(100vw - 4em)
    width: max-content
    height: max-content
    max-height: calc(100vh - 4em)
    margin: calc(10vh - 2em) auto
    background-color: #353535
    border-radius: 2em
    padding: 2em
    position: relative
    z-index: 7
    box-shadow: 0px 0px 17px 4px #00000030
    overflow: hidden

    @media screen and (max-width: 600px) 
        font-size: 14px
        position: fixed
        bottom: 0px
        margin-bottom: 0
        margin-left: 0
        margin-right: 0
        border-radius: 2em 2em 0 0
        padding-bottom: 2em
        height: max-content
        width: 100vw



.close
    position: absolute 
    top: 2em
    right: 2em
    color: white

    svg
        color: white