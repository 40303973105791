.card 
    display: flex
    flex-direction: row
    justify-content: center
    align-items: flex-start
    background: #353535
    color: #fff
    box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem, rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem
    height: 100%
    width: max-content
    border-radius: 1em
    transition: all 250ms ease-in-out
    overflow: hidden
    padding: 1em
    margin: 1em

    background-size: cover
    background-position: center
    background-repeat: no-repeat